import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { observable, computed, configure, action, runInAction, autorun, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import getProp from 'lodash/get';
import JSON5 from 'json5';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import camelCase from 'lodash/camelCase';

window.toJS = toJS;
window.observable = observable;

configure({
	enforceActions: 'never',
});

console.log('render script v-15');

const useComponentInstance = ({
	propInstance,
	component,
	props,
	propsById,
	forwardedProps,
	context,
	componentsStack,
}) => {
	const env = window.Borstch.env;

	const data = useMemo(() => {
		let data = {};
		try {
			data = JSON5.parse(component.data);
		} catch (e) {}
		return observable(data);
	}, [component]);

	const instance = useMemo(() => {
		if (propInstance) {
			return propInstance;
		}

		const instance = {
			instanceId: ++window.Borstch.counter,
			componentId: component.id,
			props,
			propsById,
			forwardedProps,
			data,
			lifecycle: {},
			methods: {},
			methodsById: {},
			nodesMap: component.nodes.reduce((map, node) => {
				map[node.id] = node;
				return map;
			}, {}),
		};

		// mapping for methods
		component.methods.forEach(({ id, callName, type, method }) => {
			instance.methods[callName] = { id, callName, type, method };
			instance.methodsById[id] = { id, callName, type, method };
		});

		// pre-init lifecycle methods
		component.lifecycle.forEach(({ lifecycle, method }) => {
			instance.lifecycle[lifecycle] = method({
				fnsToInit: env.functions,
				// methodsToInit: instance.methods,
				methodsToInitById: instance.methodsById,
				allAssets: env.assets,
				store: env.store,
				props,
				data,
				context,
				// methods,
				// fns,
				runInAction,
				autorun,
				toJS,
				computed,
			});
		});

		return instance;

		// },[]);
	}, [propInstance, component, props, propsById, context, data]);

	return instance;
};

const getIsEnabled = ({ node, values, instance, context }) => {
	if (!node.isEnabled) {
		return true;
	}
	const isEnabled = node.isEnabled;
	// if (!isEnabled.type || isEnabled.type === 'checkbox') {
	// 	return isEnabled.value;
	// }
	const calculatedIsEnabled = getSettingValue(isEnabled, { instance, values, context });
	return calculatedIsEnabled ?? true;
};

const getValueFromList = (values, valueId) => {
	const valueData = values?.find(({ id }) => id === valueId);
	// const valueRaw = getPreviewValue(valueData?.data);
	let value = parseCodeValue(valueData?.data);
	return value;
};

const parseCodeValue = (valueRaw) => {
	let value = observable({});
	try {
		value = JSON5.parse(valueRaw);
		if (typeof value === 'object' && value !== null) {
			value = observable(value);
		}
	} catch (e) {}
	return value;
};

const parseDesignSystemValue = (id, variant) => {
	if (!id || !variant) {
		return null;
	}

	const env = window.Borstch.env;

	let type = null;
	let value = null;

	Object.entries(env.designSystem || {}).some(([_type, values]) => {
		value = values.find((value) => {
			return value.id === id;
		});
		if (value) {
			type = _type;
		}
		return value;
	});

	return value?.variants?.find(({ id }) => id === variant)?.value || null;
};

const getPropValue = ({ instance, propData }) => {
	const value = getProp(instance.propsById, `${propData.prop}${propData.path ? `.${propData.path}` : ''}`);
	return value;
};

const getHasSettingDataValue = (settingData) => {
	if (!settingData?.type) {
		return false;
	}

	const { type } = settingData;

	const value =
		type === 'custom'
			? settingData.value
			: type === 'static'
			? settingData.value
			: type === 'checkbox'
			? settingData.value
			: type === 'ds'
			? settingData.id
			: type === 'parsed'
			? settingData.code
			: type === 'html'
			? settingData.code
			: type === 'image'
			? settingData.assetId
			: type === 'propValue'
			? settingData.valueId
			: type === 'domType'
			? settingData.domType
			: type === 'method'
			? settingData.method
			: type === 'data'
			? true
			: type === 'prop'
			? settingData.prop
			: type === 'store'
			? true
			: type === 'context'
			? true
			: false;

	return value !== undefined && value !== null;
};

const getSettingValue = (propData = {}, { instance, values, context, event }) => {
	const env = window.Borstch.env;
	const result = (() => {
		const { type = 'custom' } = propData;
		const value =
			type === 'custom'
				? propData.value
				: type === 'static'
				? propData.value
				: type === 'checkbox'
				? propData.value
				: type === 'ds'
				? parseDesignSystemValue(propData.id, propData.variant)
				: type === 'parsed'
				? parseCodeValue(propData.code || '')
				: type === 'html'
				? propData.code || ''
				: type === 'propValue'
				? getValueFromList(values, propData.valueId) // selected from a list of available values
				: type === 'domType'
				? propData.domType
				: type === 'method'
				? (() => {
						const methodId = propData.method;
						if (!methodId) {
							return null;
						}

						const methodData = propData.forwardedComponentInstance
							? propData.forwardedComponentInstance.methodsById[methodId]
							: instance.methodsById[methodId];

						if (!methodData) {
							return null;
						}

						const { type, method } = methodData;
						const initializedMethod = method({
							event,
							fnsToInit: env.functions,
							// methodsToInit: instance.methods,
							methodsToInitById: propData.forwardedComponentInstance
								? propData.forwardedComponentInstance.methodsById
								: instance.methodsById,
							allAssets: env.assets,
							store: env.store,
							props: propData.forwardedComponentInstance
								? propData.forwardedComponentInstance.props
								: instance.props,
							data: propData.forwardedComponentInstance
								? propData.forwardedComponentInstance.data
								: instance.data,
							context: propData.forwardedContext || context,
							// methods,
							// fns,
							runInAction,
							autorun,
							toJS,
							computed,
						});
						if (type === 'computed') {
							return initializedMethod();
						}
						return initializedMethod;
				  })()
				: type === 'image'
				? env.assets[propData.assetId]?.({
						width: propData.width,
						height: propData.height,
				  })
				: type === 'store'
				? getProp(env.store, propData.path)
				: type === 'prop'
				? getPropValue({ instance: propData.forwardedComponentInstance || instance, propData })
				: type === 'data'
				? getProp((propData.forwardedComponentInstance || instance).data, propData.path)
				: type === 'context'
				? getProp(propData.forwardedContext || context, propData.path)
				: null;
		return value;
	})();
	return result;
};

const camelToDashCase = (str) => str.replace(/([A-Z])/g, '-$1').toLowerCase();

const forEachCssConditionedValue = (settingData, { instance, context }, fn) => {
	settingData.conditioned?.forEach((conditionedSettingData) => {
		const conditions = [];

		if (conditionedSettingData.conditions.width) {
			conditions.push(`(max-width: ${conditionedSettingData.conditions.width}px)`);
		}

		if (conditionedSettingData.conditions.height) {
			conditions.push(`(max-height: ${conditionedSettingData.conditions.height}px)`);
		}

		const conditionedValue = getSettingValue(conditionedSettingData, { instance, context });
		if (!conditionedValue) {
			return;
		}

		const conditionsStr = conditions.join(' and ');

		fn({ conditions: conditionedSettingData.conditions, conditionsStr }, conditionedValue);
	});
};

const renderCssObject = (css, { instance, context, containerStyles, state }) => {
	const transform = [];
	const transformConditionedTranslate = [];
	const transformConditionedTranslateMap = {};
	let prevTranslateX = null;
	let prevTranslateY = null;

	let result = Object.entries(css)
		.map(([key, settingData]) => {
			const keyCamel = camelCase(key);
			const value = getSettingValue(settingData, { instance, context });

			if (keyCamel === 'translateX') {
				if (value) {
					transform.push(`translateX(${value})`);
					prevTranslateX = value;
				}

				forEachCssConditionedValue(
					settingData,
					{ instance, context },
					({ conditions, conditionsStr }, conditionedValue) => {
						if (!transformConditionedTranslateMap[conditionsStr]) {
							const data = {
								conditionsStr,
								values: [],
							};
							transformConditionedTranslateMap[conditionsStr] = data;
							transformConditionedTranslate.push(data);
						}

						const data = transformConditionedTranslateMap[conditionsStr];
						data.values.translateX = conditionedValue;
						// data.values.push(`translateX(${conditionedValue})`);

						// transformConditionedTranslate[conditionsStr].types.translateX = true;
						// transformConditionedTranslate[conditionsStr].values.push(`translateX(${conditionedValue})`);
						// transformConditionedTranslateX.push({conditions, value: conditionedValue});
					},
				);

				return '';
			}

			if (keyCamel === 'translateY') {
				if (value) {
					transform.push(`translateY(${value})`);
					prevTranslateY = value;
				}

				forEachCssConditionedValue(
					settingData,
					{ instance, context },
					({ conditions, conditionsStr }, conditionedValue) => {
						if (!transformConditionedTranslateMap[conditionsStr]) {
							const data = {
								conditionsStr,
								values: [],
							};
							transformConditionedTranslateMap[conditionsStr] = data;
							transformConditionedTranslate.push(data);
						}

						const data = transformConditionedTranslateMap[conditionsStr];
						data.values.translateY = conditionedValue;
						// data.values.push(`translateY(${conditionedValue})`);
					},
				);

				return '';
			}

			const result = [];

			const keyDashCase = camelToDashCase(keyCamel);

			if (keyDashCase === 'container-type') {
				state.hasCssContainer = true;
			}

			if (value) {
				result.push(`${keyDashCase}: ${value};`);
			}

			forEachCssConditionedValue(
				settingData,
				{ instance, context },
				({ conditions, conditionsStr }, conditionedValue) => {
					containerStyles.push({
						conditionsStr,
						css: `${keyDashCase}: ${conditionedValue};`,
					});
				},
			);

			return result.join('');
		})
		.join('\n');

	result += transform.length > 0 ? `transform: ${transform.join(' ')};` : '';
	transformConditionedTranslate.forEach(({ conditionsStr, values }) => {
		const translateX = values.translateX || prevTranslateX;
		prevTranslateX = translateX;
		const translateXText = translateX ? `translateX(${translateX})` : '';

		const translateY = values.translateY || prevTranslateY;
		prevTranslateY = translateY;
		const translateYText = translateY ? `translateY(${translateY})` : '';

		containerStyles.push({
			conditionsStr,
			css: `transform: ${translateXText} ${translateYText};`,
		});
	});

	return result;
};

const renderCss = ({ node, instance, context, hasComponents, nodeClassName, hasCssContainer }) => {
	const state = {
		hasCssContainer,
		hasContainerQueries: false,
	};

	const containerStyles = [];
	const result = (() => {
		return `
            ${node.css ? renderCssObject(node.css, { instance, context, containerStyles, state }) : ''}
            ${
				node.cssHover
					? `
                &:hover {
                    ${renderCssObject(node.cssHover, { instance, context, containerStyles, state })}
                }
            `
					: ''
			}
        `;
	})();

	let customStyle = null;
	if (containerStyles.length > 0) {
		state.hasContainerQueries = true;
		customStyle = containerStyles
			.map(({ conditionsStr, css }) => {
				return `
                ${hasCssContainer ? '@container' : '@media'} ${conditionsStr} {
                    .${nodeClassName} {
                        ${css}
                    }
                }
            `;
			})
			.join('\n');
	}

	// if(hasComponents){

	//     result = `
	//         container-type: size;
	//         container-name: ${nodeClassName};
	//         ${result}
	//     `;
	// }

	return [result, customStyle, state];
};
const renderAttrs = ({ node, instance, context }) => {
	const result = (() => {
		return Object.entries(node.attrs || {}).reduce((map, [name, attrData]) => {
			const value = getSettingValue(attrData, { instance, context });

			if (value !== undefined) {
				map[name] = value;
			}

			return map;
		}, {});
	})();

	return result;
};

const getEventHandlers = ({ node, instance, context }) => {
	const env = window.Borstch.env;
	const result = (() => {
		return Object.entries(node.eventHandlers || {}).reduce((map, [eventName, eventHandlerData]) => {
			// const {method, type} = instance.methodsById[methodId];
			map[eventName] = (event) => {
				const fn = getSettingValue(eventHandlerData, {
					instance,
					context,
					event: event.nativeEvent,
				});
				// const methodWithEnv = (
				//     // methods[callName] ||
				//     method({
				//         // const methodWithEnv = method({
				//         event: event.nativeEvent,
				//         fnsToInit: env.functions,
				//         methodsToInit: instance.methods,
				//         allAssets: env.assets,
				//         store: env.store,
				//         props: instance.props,
				//         data: instance.data,
				//         context,
				//         // methods,
				//         // fns,
				//         runInAction,
				//         toJS,
				//         computed,
				//     })
				// );
				if (typeof fn !== 'function') {
					// console.error({
					//     eventName,
					//     event,
					//     node,
					//     eventHandlerData,
					// });
					// throw new Error(`Event handler function for event "${eventName}" is not found.`);
					return;
				}
				fn();
			};
			return map;
		}, {});
	})();
	return result;
};

export const getProps = ({ instance, context, propsSettings, forwardedProps, childComponent }) => {
	const result = (() => {
		// const propsMap = (childComponent.props || []).reduce((map, prop) => {
		// 	map[prop.id] = prop;
		// 	return map;
		// }, {});
		const props = {};
		const propsById = {};

		childComponent.props?.forEach((prop) => {
			const propId = prop.id;
			const hasSettingDataValue = getHasSettingDataValue(forwardedProps?.props?.[propId]);
			const propData = hasSettingDataValue ? forwardedProps.props[propId] : propsSettings?.[propId];

			if (!propData) {
				return;
			}

			let value =
				propData.type === 'propValue'
					? getValueFromList(prop.values, propData.valueId)
					: getSettingValue(propData, { instance, values: prop?.values, context });

			props[prop.name] = value;
			propsById[propId] = value;
		});

		childComponent.props?.forEach((prop) => {
			if (
				(propsById[prop.id] !== undefined && propsById[prop.id] !== null && propsById[prop.id] !== '') ||
				!prop.default
			) {
				return;
			}

			const value = parseCodeValue(prop.default);

			props[prop.name] = value;
			propsById[prop.id] = value;
		});

		return { props, propsById };
	})();
	return result;
};

const NodesList = observer(function NodesList({
	props,
	propsById,
	component,
	instance: propInstance,
	nodeIds = [],
	componentsStack,
	noDomInstancesStack = [],
	context = {},
	isComponentRootDomNode,
	hasCssContainer = false,
	// methods = {},
	// fns = {},
}) {
	const env = window.Borstch.env;

	const instance = useComponentInstance({
		propInstance,
		component,
		props,
		propsById,
		context,
		componentsStack,
		// methods,
		// fns,
	});

	useMemo(() => {
		if (propInstance) {
			return;
		}

		const Borstch = window.Borstch;
		Borstch.instances = {};
		Borstch.tree = {};
		Borstch.treeRefs = {};
		Borstch.tree.root = instance.instanceId;
		const tree = Borstch.tree;
		const treeRefs = Borstch.treeRefs;

		tree[instance.instanceId] = {
			componentName: env.componentsMap[instance.componentId].name,
			// instanceId: instance.instanceId,
			// component: env.componentsMap[instance.componentId],
			// instance,
			// context,
			children: {},
			// parent: null,
		};
		treeRefs[instance.instanceId] = {
			ref: null,
		};
		Borstch.instances[instance.instanceId] = {
			// component: env.componentsMap[instance.componentId],
			instance,
			context,
		};
		Borstch.updateTreeVersion();
	}, []);

	useEffect(() => {
		return () => {
			if (propInstance) {
				return;
			}

			// tree.clear();
			Borstch.tree = {};
			Borstch.treeRefs = {};
			Borstch.instances = {};
		};
	}, []);

	useEffect(() => {
		if (propInstance) {
			return;
		}
		instance.lifecycle.mounted?.();
		return () => {
			instance.lifecycle.destroy?.();
		};
	}, [propInstance]);

	const componentsStackValue = useMemo(() => {
		return (
			componentsStack ||
			(component.id
				? {
						[component.id]: 1,
				  }
				: {})
		);
	}, [componentsStack, component]);

	const nextNoDomInstancesStack = useMemo(() => {
		if (propInstance) {
			return noDomInstancesStack;
		}

		return [...noDomInstancesStack, instance.instanceId];
	}, [noDomInstancesStack]);

	return (
		<>
			{nodeIds.map((nodeId) => {
				// const node = component.nodes.find(({id}) => id === nodeId);
				const node = instance.nodesMap[nodeId];

				const isEnabled = getIsEnabled({
					node,
					component,
					instance,
					context,
					propsSettings: node.props,
					forwardedProps: instance.forwardedProps?.[node.id],
				});
				if (!isEnabled) {
					return null;
				}

				if (node.type === 'component') {
					if (componentsStack?.[node.componentId] > 50) {
						// circular
						return null;
					}

					if (!env.componentsMap[node.componentId]) {
						return null;
					}
				}

				const Component = components[node.type];

				return (
					<Component
						component={component}
						instance={instance}
						node={node}
						componentsStack={componentsStackValue}
						noDomInstancesStack={nextNoDomInstancesStack}
						context={context}
						isComponentRootDomNode={isComponentRootDomNode}
						hasCssContainer={hasCssContainer}
						// methods={methods}
						// fns={fns}
						key={nodeId}
					/>
				);
			})}
		</>
	);
});
export default NodesList;

export const HighlightPreview = observer(function HighlightPreview() {
	const Borstch = window.Borstch;

	if (!Borstch.highlightComponent) {
		return null;
	}

	// const isEnabled = Borstch.highlightComponent.getIsEnabled();
	const highlightInstanceId = Borstch.highlightComponent.getInstanceId();
	const instanceId = highlightInstanceId.stack.length > 0 && highlightInstanceId.stack[0];
	const treeItem = instanceId && Borstch.tree[instanceId];
	const treeRefsItem = instanceId && Borstch.treeRefs[instanceId];
	const ref = treeRefsItem?.ref;

	const { x, y, width, height } = ref?.current.getBoundingClientRect() || {};

	if (!instanceId || !ref) {
		return null;
	}

	const componentName = treeItem?.componentName;

	return (
		<>
			<div
				style={{
					position: 'fixed',
					top: y,
					left: x,
					// display: 'flex',
					// alignItems: 'center',
					// justifyContent: 'center',
					padding: 8,
					width,
					height,
					border: '1px rgba(122, 0, 255, 0.7) solid',
					backgroundColor: 'rgba(122, 0, 255, 0.3)',
					color: 'white',
					fontSize: 12,
					overflow: 'hidden',
					pointerEvents: 'none',
					cursor: 'pointer',
				}}
			>
				{componentName || ''}
			</div>
		</>
	);
});

const getDomType = (domType, { instance, context }) => {
	const result = typeof domType === 'string' ? domType : getSettingValue(domType, { instance, context });
	return result?.trim() || 'div';
};

let nodeCounter = 0;
const getUniqueClassName = () => `bo-${++nodeCounter}`;

const NodeDom = observer(function NodeDom({
	component,
	instance,
	node,
	componentsStack,
	noDomInstancesStack,
	context,
	isComponentRootDomNode,
	// methods,
	// fns,
}) {
	const Borstch = window.Borstch;

	const [nodeClassName] = useState(getUniqueClassName);
	const hasComponents = useMemo(() => {
		return (
			node.nodeIds?.some((nodeId) => {
				// const node = component.nodes.find(({id}) => id === nodeId);
				const node = instance.nodesMap[nodeId];
				return node.type === 'component';
			}) || false
		);
	}, [node.nodeIds]);

	const domType = getDomType(node.domType, { instance, context });
	const Component = StyledComponents[domType];

	let [styles, customStyle, { hasCssContainer, hasContainerQueries }] = renderCss({
		node,
		instance,
		context,
		hasComponents,
		nodeClassName,
	});
	const attrs = renderAttrs({ node, instance, context });
	const eventHandlers = getEventHandlers({ node, instance, context });

	const highlightComponentIsEnabled = Borstch.highlightComponent?.getIsEnabled();
	const onMouseEnterWithHighlight = useCallback(() => {
		const highlightInstanceId = Borstch.highlightComponent.getInstanceId();
		Borstch.highlightComponent.setInstanceId({
			stack: [instance.instanceId, ...highlightInstanceId.stack],
			isFromPage: true,
			devToolsSelect: null,
		});
	}, []);
	const onMouseLeaveWithHighlight = useCallback(() => {
		const highlightInstanceId = Borstch.highlightComponent.getInstanceId();
		Borstch.highlightComponent.setInstanceId({
			stack: highlightInstanceId.stack.filter((instanceId) => instanceId !== instance.instanceId),
			isFromPage: true,
			devToolsSelect: null,
		});
	}, []);
	const onClickHighlight = useCallback((e) => {
		e.stopPropagation();
		Borstch.highlightComponent.setInstanceId({
			stack: [],
			isFromPage: true,
			devToolsSelect: instance.instanceId,
		});
	}, []);

	if (highlightComponentIsEnabled && isComponentRootDomNode) {
		eventHandlers.onMouseEnter = onMouseEnterWithHighlight;
		eventHandlers.onMouseLeave = onMouseLeaveWithHighlight;
		eventHandlers.onClick = onClickHighlight;
	}

	const ref = useRef();

	const props = {
		ref,
		className: hasContainerQueries ? nodeClassName : undefined,
		'data-borstch-instance-ids': (noDomInstancesStack.length > 0 ? noDomInstancesStack : undefined)?.join(' '),
		styles,
		isComponentRootDomNode: false,
		...attrs,
		...eventHandlers,
	};

	if (node.html) {
		const html = getSettingValue(node.html, { instance, context });
		if (html) {
			props.dangerouslySetInnerHTML = { __html: html };
		}
	}

	const nextNoDomInstancesStack = useMemo(() => [], []);

	useEffect(() => {
		if (isComponentRootDomNode) {
			Borstch.treeRefs[instance.instanceId].ref = ref;
		}
	}, []);

	if (domType === 'text') {
		const text = node.attrs?.value && getSettingValue(node.attrs.value, { instance, context });
		return text ? (typeof text !== 'string' ? JSON.stringify(text) : text) : '';
	}

	if (withoutChildren[domType] || props.dangerouslySetInnerHTML) {
		return (
			<>
				<Component {...props} />
				{customStyle && <style dangerouslySetInnerHTML={{ __html: customStyle }} />}
			</>
		);
	}

	return (
		<>
			<Component {...props}>
				{node.nodeIds?.length > 0 ? (
					<NodesList
						component={component}
						instance={instance}
						nodeIds={node.nodeIds}
						componentsStack={componentsStack}
						noDomInstancesStack={nextNoDomInstancesStack}
						context={context}
						hasCssContainer={hasCssContainer}
						// methods={methods}
						// fns={fns}
					/>
				) : null}
			</Component>
			{customStyle && <style dangerouslySetInnerHTML={{ __html: customStyle }} />}
		</>
	);
});

const domTypes = [
	'div',
	'span',
	'p',
	'text',
	'article',
	'section',
	'header',
	'footer',
	'aside',
	'main',
	'nav',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'a',
	'b',
	'strong',
	'blockquote',
	'button',
	'input',
	'textarea',
	'form',
	'label',
	'img',
	'table',
	'thead',
	'tbody',
	'th',
	'tr',
	'td',
];
const StyledComponents = domTypes.reduce((map, domType) => {
	map[domType] = styled[domType]`
		${({ styles }) => styles || ''}
	`;
	return map;
}, {});

function enrichWithContext(forwardedProps, { instance, context }) {
	// not to mutate the data inside a node
	// just copy is a simple solution for now
	// we are not updating the stored data
	// this is just temporary data within the rendering
	forwardedProps = JSON.parse(JSON.stringify(forwardedProps));

	const nodeForwardedProps = Object.values(forwardedProps);

	while (nodeForwardedProps.length > 0) {
		const nodeForwardedProp = nodeForwardedProps.shift();

		Object.values(nodeForwardedProp.props || {}).forEach((prop) => {
			prop.forwardedComponentInstance = instance;
			prop.forwardedContext = context;
		});

		if (nodeForwardedProp.forwardedProps) {
			nodeForwardedProps.push(...Object.values(nodeForwardedProp.forwardedProps));
		}
	}

	return forwardedProps;
}

const NodeComponent = observer(function NodeComponent({
	component,
	instance,
	node,
	componentsStack,
	noDomInstancesStack,
	context,
	hasCssContainer,
	// methods,
	// fns,
}) {
	const env = window.Borstch.env;

	const childComponent = env.componentsMap[node.componentId];

	// const nextMethods = useMemo(() => ({}), []);
	// const nextFns = useMemo(() => ({}), []);

	const childProps = getProps({
		instance,
		context,
		propsSettings: node.props,
		forwardedProps: instance.forwardedProps?.[node.id],
		childComponent,
	});

	const nodeForwardedProps = enrichWithContext(node.forwardedProps || {}, { instance, context });
	const childForwardedProps = mergeWith(
		{},
		instance.forwardedProps?.[node.id]?.forwardedProps || {},
		nodeForwardedProps,
		(objValue, srcValue, key) => {
			if (key === 'forwardedComponentInstance' || key === 'forwardedContext') {
				return srcValue;
			}
		},
	);

	const childInstance = useComponentInstance({
		component: childComponent,
		props: childProps.props,
		propsById: childProps.propsById,
		forwardedProps: childForwardedProps,
		context,
		// methods: nextMethods,
		// fns: nextFns,
		componentsStack,
	});

	const nextContext = useMemo(() => observable({}), []);

	useEffect(() => {
		childInstance.lifecycle.mounted?.();
		return () => {
			childInstance.lifecycle.destroy?.();
		};
	}, []);

	useMemo(() => {
		const tree = window.Borstch.tree;
		const treeRefs = window.Borstch.treeRefs;
		const componentInTree = tree[instance.instanceId];
		componentInTree.children[childInstance.instanceId] = true;

		tree[childInstance.instanceId] = {
			componentName: env.componentsMap[childInstance.componentId].name,
			// instanceId: childInstance.instanceId,
			// component: env.componentsMap[childInstance.componentId],
			// instance,
			// context,
			children: {},
			// parent: instance,
		};
		treeRefs[childInstance.instanceId] = {
			refs: null,
		};

		Borstch.instances[childInstance.instanceId] = {
			// component: env.componentsMap[instance.componentId],
			instance: childInstance,
			context,
		};
		Borstch.updateTreeVersion();
		// },[]);
	}, [instance, childInstance]);

	useEffect(() => {
		return () => {
			const tree = window.Borstch.tree;
			const treeRefs = Borstch.treeRefs;
			const componentInTree = tree?.[instance.instanceId];
			if (componentInTree) {
				delete componentInTree.children[childInstance.instanceId];
			}
			if (tree) {
				delete tree[childInstance.instanceId];
				delete treeRefs[childInstance.instanceId];
			}
			if (Borstch.instances) {
				delete Borstch.instances[childInstance.instanceId];
			}
			Borstch.updateTreeVersion();
		};
		// },[]);
	}, [instance, childInstance]);

	const componentsStackValue = useMemo(() => {
		return {
			...componentsStack,
			[node.componentId]: (componentsStack[node.componentId] || 0) + 1,
		};
	}, [componentsStack, node]);

	const nextNoDomInstancesStack = useMemo(() => {
		return [...noDomInstancesStack, childInstance.instanceId];
	}, [noDomInstancesStack]);

	return (
		<NodesList
			component={childComponent}
			instance={childInstance}
			nodeIds={childComponent.nodeIds}
			componentsStack={componentsStackValue}
			noDomInstancesStack={nextNoDomInstancesStack}
			context={nextContext}
			isComponentRootDomNode={true}
			hasCssContainer={hasCssContainer}
			// methods={nextMethods}
			// fns={nextFns}
		/>
	);
});

const NodeGroup = observer(function NodeGroup({
	component,
	instance,
	node,
	componentsStack,
	noDomInstancesStack,
	context,
	isComponentRootDomNode,
	hasCssContainer,
	// methods,
	// fns,
}) {
	return (
		<NodesList
			component={component}
			instance={instance}
			nodeIds={node.nodeIds}
			componentsStack={componentsStack}
			noDomInstancesStack={noDomInstancesStack}
			context={context}
			isComponentRootDomNode={isComponentRootDomNode}
			hasCssContainer={hasCssContainer}
			// methods={methods}
			// fns={fns}
		/>
	);
});

const NodeList = observer(function NodeList({
	component,
	instance,
	node,
	componentsStack,
	noDomInstancesStack,
	context,
	isComponentRootDomNode,
	hasCssContainer,
	// methods,
	// fns,
}) {
	let listData = getSettingValue(node.data, { instance, context });

	if (listData === undefined) {
		return null;
	}

	listData = Array.isArray(listData) ? listData : [];

	// return null;
	return listData.map?.((data, index) => {
		const key = getProp(data, node.listItemKeyPath) + '-' + index;

		return (
			<NodeListItem
				data={data}
				index={index}
				context={context}
				// methods={methods}
				// fns={fns}
				node={node}
				component={component}
				instance={instance}
				componentsStack={componentsStack}
				noDomInstancesStack={noDomInstancesStack}
				isComponentRootDomNode={isComponentRootDomNode}
				hasCssContainer={hasCssContainer}
				key={key}
			/>
		);
	});
});

const NodeListItem = observer(function NodeListItem({
	data,
	index,
	context,
	// methods,
	// fns,
	node,
	component,
	instance,
	componentsStack,
	noDomInstancesStack,
	isComponentRootDomNode,
}) {
	const nextContext = useMemo(() => {
		const nextContext = {
			...context,
		};
		if (node.contextName) {
			nextContext[node.contextName] = {
				data,
				index,
			};
		}
		return observable(nextContext);
	}, [context, node.contextName, data, index]);

	// const nextMethods = useMemo(() => ({}), []);
	// const nextFns = useMemo(() => ({}), []);

	return (
		<NodesList
			component={component}
			instance={instance}
			nodeIds={node.nodeIds}
			context={nextContext}
			// methods={nextMethods}
			// fns={nextFns}
			componentsStack={componentsStack}
			noDomInstancesStack={noDomInstancesStack}
			isComponentRootDomNode={isComponentRootDomNode}
		/>
	);
});

// const NodeSlot = observer(function NodeSlot({node, nodesStore, layerCounter}){

// 	return (
// 		<NodeItem
// 			layerCounter={layerCounter}
// 			node={node}
// 		>
// 			Slot {node.name}
// 		</NodeItem>
// 	);
// });

const components = {
	dom: NodeDom,
	component: NodeComponent,
	group: NodeGroup,
	list: NodeList,
	// 'slot': NodeSlot,
};
const withoutChildren = {
	input: true,
	textarea: true,
	img: true,
};
