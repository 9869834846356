import ReactDOM, { hydrate } from 'react-dom';
import { renderToString } from 'react-dom/server';
import Render, { getProps, HighlightPreview } from './render';
import { observable, computed, configure, action, runInAction, toJS } from 'mobx';

(() => {
	const Borstch = window.Borstch;
	Borstch.toJS = toJS;
	const env = Borstch.env;

	try {
		env.store = observable(JSON.parse(env.store));
	} catch (e) {
		env.store = observable({});
	}
	try {
		env.designSystem = observable(JSON.parse(env.designSystem || '{}'));
	} catch (e) {
		env.designSystem = observable({});
	}
	Borstch.highlightInstances = observable({});
	Borstch.highlightComponent.data = observable(Borstch.highlightComponent.data);

	const component = env.componentsMap[env.url.componentId];
	const childProps = getProps({
		propsSettings: env.url.props,
		childComponent: component,
	});

	const appRoot = document.querySelector('#borstch-app-root');
	hydrate(
		<>
			<Render
				component={component}
				nodeIds={component.nodeIds}
				props={childProps.props}
				propsById={childProps.propsById}
				isComponentRootDomNode
			/>
			<HighlightPreview />
		</>,
		appRoot,
	);
})();
